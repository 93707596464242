<template>
  <vx-card class="border-grey border-solid border p-0">
    <div class="grid grid-cols-12 gap-4">
      <div class="col-span-12">
        <vx-card class="mx-0 px-0 shadow-none">
          <div class="grid gap-2">
            <div v-if="questionnaireName" class="flex flex-col row-start-1 col-span-3">
              <label class="font-bold">{{ $t('Questionnaire') }}</label>
              <span>{{questionnaireName}}</span>
            </div>

            <div v-if="userName && userEmail" class="flex flex-col row-start-2">
              <label class="font-bold">{{ $t('usuario-a') }}</label>
              <span>{{ $t('username-useremail', [userName, userEmail]) }}</span>
            </div>

            <div v-if="questionnairePublishedAt" class="flex flex-col row-start-2">
              <label class="font-bold">{{ $t('Release_Date') }}</label>
              <span>{{questionnairePublishedAt}}</span>
            </div>
            <div v-if="answerFinishedAt" class="flex flex-col row-start-2">
              <label class="font-bold">{{ $t('data-finalizacao') }}</label>
              <span>{{answerFinishedAt}}</span>
            </div>
            <div v-if="showResultScore && answerScore && questionnaireTotalScore" class="flex flex-col row-start-4">
              <label class="font-bold">{{ $t('points') }}</label>
              <span>{{formatFloat(answerScore)}} / {{formatFloat(questionnaireTotalScore)}} {{scorePercentage()}}</span>
            </div>
          </div>
        </vx-card>
      </div>
      <div class="col-span-12">
        <div>
          <div class="px-4" v-for="(field, i) in answeredQuestionnaireFields" :key="i">
            <vx-card :class="getRowClass(field)">
              <div class="grid grid-rows-1" v-if="_.get(field, 'type', null) !== 'section'">
                <div class="grid grid-cols-2">
                  <div class="float-left">
                    <span style="font-size: 12px" v-if="showQuestionScore">{{ scoreSet(field) }}</span>
                  </div>
                  <div class="float-right">
                    <label v-if="showScore" :class="field.correction
                          ? 'float-right text-right text-success'
                          : 'float-right text-right text-danger'
                      ">{{`${$t('points')}: ${field.score ? formmatedScore(field.score) : 0}`}}
                      </label>
                  </div>
                </div>
              </div>
              <div>
                <SectionAnswer
                  :item="{ data: { title: field.title, description: field.description } }"
                  v-if="field.type === 'section'"
                ></SectionAnswer>
                <SimpleTextShow
                  :item="{
                    data: {
                      ...getQuestionData(field),
                      title: field.title
                    },
                    answer: field.answer,
                    correction: field.correction,
                    feedback: field.feedback,
                  }"
                  v-if="field.type === 'simple_text'"
                ></SimpleTextShow>
                <MultipleChoiceShow
                  :item="{
                    data: {
                      ...getQuestionData(field),
                      title: field.title,
                      options: field.options,
                    },
                    chosen_option_id: field.answer,
                    correction: field.correction,
                    feedback: field.feedback,
                  }"
                  :highlightCorrectBackground="false"
                  v-if="field.type === 'multiple_choice'"
                ></MultipleChoiceShow>
                <CheckBoxShow
                  :item="{
                    data: {
                      ...getQuestionData(field),
                      title: field.title,
                      options: field.options,
                    },
                    chosen_option_id: field.answer,
                    correction: field.correction,
                    feedback: field.feedback,
                  }"
                  :highlightCorrectBackground="false"
                  v-if="field.type === 'check_box'"
                ></CheckBoxShow>
                <GapShow
                  :item="{
                    answer: field.answer,
                    data: {
                      ...getQuestionData(field),
                      title: field.title,
                      options: field.options,
                    },
                    chosen_option_id: field.answer,
                    correction: field.correction,
                    feedback: field.feedback,
                  }"
                  :highlightCorrectBackground="false"
                  v-if="field.type === 'gap'"
                ></GapShow>
                <EssayShow
                  :item="{
                    data: {
                      ...getQuestionData(field),
                      title: field.title
                    },
                    answer: field.answer,
                    correction: field.correction,
                    feedback: field.feedback,
                  }"
                  v-if="field.type === 'essay'"
                ></EssayShow>
              </div>
            </vx-card>
          </div>
          <div class="vx-col grid w-full mt-6" v-if="showLogo">
            <div v-if="organizationLogo" class="flex items-stretch mx-auto place-self-center">
              <div class="flex items-center">
                <label class="text-black text-sm mr-2 text-center">{{$t('made_by')}}</label>
              </div>
              <img :src="organizationLogo" style="max-width: 100px"/>
            </div>
            <div class="flex items-stretch mx-auto place-self-center">
              <label class="text-primary text-sm mr-2">{{$t('Powered_by')}}</label>
              <a href="https://educatena.com.br" target="_blank">
                <img src="@/assets/images/logo/logo.png" style="max-width: 100px"/>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </vx-card>
</template>

<script>
import SectionAnswer from './questions/SectionAnswer'
import SimpleTextShow from './questions/show/SimpleTextShow'
import EssayShow from './questions/show/EssayShow'
import MultipleChoiceShow from './questions/show/MultipleChoiceShow'
import CheckBoxShow from './questions/show/CheckBoxShow'
import GapShow from './questions/show/GapShow'
import { getQuestion } from '@/util/QuestionnaireUtil'

import OrganizationService from '@/services/api/OrganizationService'

export default {
  components: {
    SectionAnswer,
    SimpleTextShow,
    MultipleChoiceShow,
    CheckBoxShow,
    GapShow,
    EssayShow
  },
  props : {
    answer: {
      type: Object,
      default: null
    },
    questionnaire_data: {
      type: Object,
      default: null
    },
    questionnaire_short_url: {
      type: String,
      default: null
    },
    logo: {
      type: String,
      default: null
    },
    showLogo:{
      type: Boolean,
      default: true
    },
    showScore:{
      type: Boolean,
      default: true
    },
    showQuestionScore:{
      type: Boolean,
      default: true
    },
    showResultScore:{
      type: Boolean,
      default: true
    }
  },

  data: () => ({
    organizationService: null,
    organizationLogo: null,
  }),

  computed: {
    userName() {
      return this._.get(this.answer, 'user.name', null)
    },
    userEmail() {
      return this._.get(this.answer, 'user.email', null)
    },
    questionnairePublishedAt() {
      return this._.get(this.questionnaire_data, 'published_at_formatted', null)
    },
    answerFields() {
      const data = this._.get(this.answer, 'data', null)
      if (data) {
        try {
          const parsedData = JSON.parse(data)
          return (this._.isArray(parsedData) && parsedData) || null
        } catch (error) {
          if (error instanceof SyntaxError) {
            return null
          } else {
            console.error(error)
            return null
          }
        }
      } else {
        return null
      }
    },
    answerFinishedAt() {
      return this._.get(this.answer, 'finished_at_formatted', null)
    },
    answerScore() {
      return this._.get(this.answer, 'score', null)
    },
    questionnaireFields() {
      return this._.get(this.answer, 'questions', []) || []
    },
    questionnaireName() {
      return this._.get(this.questionnaire_data || {}, 'name', null)
    },
    questionnaireTotalScore() {
      if (this.answeredQuestionnaireFields && this.answeredQuestionnaireFields.length > 0) {
        const scoreSum = this._.sumBy(this.answeredQuestionnaireFields, (field) => {
          const score = this._.get(field, 'max_score', 0) || 0
          return parseFloat(score)
        })
        return scoreSum
      } return '?'
    },
    answeredQuestionnaireFields() {
      const hasQuestions = this.questionnaireFields.length > 0
      const hasAnswers = this.answerFields.length > 0

      if (hasQuestions && hasAnswers) {
        // We fuse together every question with its corresponding answer and return
        // the resulting array.
        const questions = this._.cloneDeep(this.questionnaireFields)

        return questions.map((question) => {
          const questionClone = this._.cloneDeep(question)
          const answer = this.answerFields.find((questionAnswer) => questionAnswer.id === question.id)
          const questionData = question.data
          delete question.data

          if (answer) {
            return {...question, ...questionData, ...answer, max_score: questionData.score}
          } else {
            return {...question, ...questionData}
          }
        })
      } else {
        return []
      }
    }
  },
  methods : {
    scorePercentage(answeredQuestion) {
      if (this.questionnaireTotalScore <= 0) return ''
      const percentage = Math.ceil((this.answerScore / this.questionnaireTotalScore) * 100)
      return `( ${percentage}% )`
    },
    scoreSet(field) {
      if (!this.isEmpty(field.max_score)) {
        const scoreset = parseFloat(field.max_score).toFixed(2).replace('.', ',')
        return this.$t('valor-scoreset', [scoreset])
      }
      return ''
    },
    formmatedScore(score) {
      return !this.isEmpty(score) ? parseFloat(score).toFixed(2).replace('.', ',') : ''
    },
    getRowClass(data) {
      const base = 'mb-2'
      const questionId = this._.get(data, 'id', null)

      if (questionId) {
        const question = getQuestion(questionId, this.answeredQuestionnaireFields, {})
        const isInvalidated = this._.get(question, 'invalidated.justification', false)
        if (isInvalidated !== false) {
          return `${base} invalidated-item`
        }
      }

      return base
    },
    getQuestionData(field) {
      const question = this.questionnaireFields.find((question) => question.id === field.id)

      if (question) {
        return question.data
      }
    }
  },

  beforeMount() {
    this.organizationLogo = this.logo
  }
}
</script>

<style lang="scss" scoped>
.vx-card /deep/ .vx-card__body {
  @apply px-2;
  @apply py-2;
  // padding: 0px;
}
</style>
