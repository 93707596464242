var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(_vm.rootComponent, { tag: "component" }, [
    _c(
      "div",
      { staticClass: "grid grid-cols-12 gap-4" },
      [
        !_vm.printMode
          ? _c("div", { staticClass: "col-span-12" }, [
              _c("fieldset", { staticClass: "p-2 pt-0 border border-grey" }, [
                _c("legend", { staticClass: "px-1 font-bol" }, [
                  _vm._v(" " + _vm._s(_vm.$t("filtros")) + " "),
                ]),
                _c(
                  "div",
                  { staticClass: "grid items-end p-2 gap-4" },
                  [
                    _c("tag-suggestion", {
                      ref: "select_questionnaire",
                      staticClass: "row-start-1",
                      attrs: {
                        max: 20,
                        column: "name,description",
                        model: "ContentQuestionnaire",
                        label: _vm.$t("questionarios"),
                        with: ["answers.user"],
                        modelToTagMapping: function (model) {
                          return {
                            text: model.name,
                            data: model.id,
                          }
                        },
                        placeholderText: "Digite o nome do questionário",
                      },
                      model: {
                        value: _vm.questionnaires,
                        callback: function ($$v) {
                          _vm.questionnaires = $$v
                        },
                        expression: "questionnaires",
                      },
                    }),
                    _c(
                      "div",
                      {
                        staticClass:
                          "row-start-2 sm:flex-no-wrap flex-wrap flex items-end gap-2",
                      },
                      [
                        _c("user-repository", {
                          staticClass: "flex-grow",
                          attrs: { userRepository: _vm.userRepository },
                          model: {
                            value: _vm.selectedUsers,
                            callback: function ($$v) {
                              _vm.selectedUsers = $$v
                            },
                            expression: "selectedUsers",
                          },
                        }),
                        _c(
                          "div",
                          { staticClass: "flex flex-row flex-shrink-0" },
                          [
                            _c(
                              "vs-button",
                              {
                                staticClass: "py-auto px-4 mr-2",
                                attrs: {
                                  icon: "print",
                                  disabled: !_vm.validFilters,
                                },
                                on: { click: _vm.print },
                              },
                              [_vm._v(_vm._s(_vm.$t("imprimir")))]
                            ),
                            _c(
                              "vs-button",
                              {
                                staticClass: "py-auto px-4",
                                attrs: { type: "border" },
                                on: { click: _vm.clearFilters },
                              },
                              [_vm._v(_vm._s(_vm.$t("action.clear")))]
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]),
            ])
          : _vm._e(),
        _vm.showBrowserWarning && !_vm.printMode
          ? _c(
              "vs-alert",
              {
                staticClass: "mt-5 col-span-12 text-warning mb-4",
                attrs: {
                  title: _vm.$t("alerta"),
                  color: "rgb(231, 154, 23)",
                  active: "true",
                },
              },
              [
                _vm._v(
                  " " +
                    _vm._s(_vm.$t("melhor-visualizacao-e-impressao-na-versa")) +
                    " "
                ),
              ]
            )
          : _vm._e(),
        _vm.printMode || (_vm.printmode && _vm.validFilters)
          ? _c(
              "div",
              { staticClass: "col-span-12 page mx-auto mt-4" },
              _vm._l(_vm.currentAnswersPage, function (answer) {
                return _c(
                  "div",
                  { key: answer.id, staticClass: "pagebreak" },
                  [
                    _c("full-answer-result", {
                      staticClass: "mb-2",
                      attrs: {
                        answer: answer,
                        questionnaire_data: _vm.getQuestionnaire(
                          answer.questionnaire_id
                        ),
                        logo: _vm.getQuestionnaireLogo(
                          _vm._.get(answer, "questionnaire_id")
                        ),
                        showLogo: _vm.showLogo,
                        showScore: _vm.showScore,
                        showQuestionScore: _vm.showQuestionScore,
                        showResultScore: _vm.showResultScore,
                      },
                    }),
                  ],
                  1
                )
              }),
              0
            )
          : _vm._e(),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }