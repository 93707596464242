<template>
  <component :is="rootComponent">
    <div class="grid grid-cols-12 gap-4">
      <div class="col-span-12" v-if="!printMode">
        <fieldset class="p-2 pt-0 border border-grey">
          <legend class="px-1 font-bol"> {{ $t('filtros') }} </legend>
          <div class="grid items-end p-2 gap-4">
            <tag-suggestion
              :max="20"
              class="row-start-1"
              column="name,description"
              model="ContentQuestionnaire"
              :label="$t('questionarios')"
              :with="['answers.user']"
              :modelToTagMapping="
                (model) => ({
                  text: model.name,
                  data: model.id
                })
              "
              v-model="questionnaires"
              ref="select_questionnaire"
              placeholderText="Digite o nome do questionário"
            />
            <div class="row-start-2 sm:flex-no-wrap flex-wrap flex items-end gap-2">
              <user-repository
                class="flex-grow"
                :userRepository="userRepository"
                v-model="selectedUsers"
              />
              <div class="flex flex-row flex-shrink-0">
                <vs-button icon="print" :disabled="!validFilters" class="py-auto px-4 mr-2" @click="print">{{ $t('imprimir') }}</vs-button>
                <vs-button class="py-auto px-4" type="border" @click="clearFilters">{{ $t('action.clear') }}</vs-button>
              </div>
            </div>

          </div>
        </fieldset>
      </div>
      <vs-alert
        v-if="showBrowserWarning && !printMode"
        :title="$t('alerta')"
        color="rgb(231, 154, 23)"
        active="true"
        class="mt-5 col-span-12 text-warning mb-4"
      >
        {{ $t('melhor-visualizacao-e-impressao-na-versa') }}
      </vs-alert>

      <div class="col-span-12 page mx-auto mt-4" v-if="(printMode) || (printmode && validFilters)">
        <div v-for="answer in currentAnswersPage" :key="answer.id" class="pagebreak">
          <full-answer-result  class="mb-2"
            :answer="answer"
            :questionnaire_data="getQuestionnaire(answer.questionnaire_id)"
            :logo="getQuestionnaireLogo(_.get(answer, 'questionnaire_id'))"
            :showLogo="showLogo"
            :showScore="showScore"
            :showQuestionScore="showQuestionScore"
            :showResultScore="showResultScore"/>
        </div>
      </div>
    </div>
  </component>
</template>

<script>
import ReportService from '@/services/api/ReportService'
import ObjectRepository from '@/models/ObjectRepository'
import TagSuggestion from '@/components/TagSuggestion.vue'
import { isArray } from 'lodash'
import ContentQuestionnaireService from '@/services/api/ContentQuestionnaireService'
import FullAnswerResult from '../questionnaires/answers/FullAnswerResult.vue'
import OrganizationService from '@/services/api/OrganizationService'
import UserRepository from '@/components/UserRepository.vue'

export default {
  components: { TagSuggestion, FullAnswerResult, UserRepository},

  data: () => ({
    answers: [],
    service: null,
    questionnaireService: null,
    selectedUsers: [],
    questionnaires: [],
    userRepository: null,
    loadedQuestionnaires: [],
    perPage: -1,
    page: 1
  }),

  props: {
    mode: {
      type: String,
      default: 'form'
    }
  },

  watch: {
    questionnaires(questionnaires) {
      if (this.questionnaireService && questionnaires && isArray(questionnaires) && questionnaires.length > 0) {
        this.$vs.loading()

        /**
         * When a new questionnaire is selected, we load every participant it has.
         */
        const participantRequests = []

        questionnaires.forEach((questionnaireTagData) => {
          console.log('tagdata', questionnaireTagData)
          participantRequests.push(this.questionnaireService.getParticipants(questionnaireTagData.data, 3))
        })

        Promise.all(participantRequests).then(
          (results) => {
            const users = []

            results.forEach((participants) => {
              participants.forEach((participant) => {
                // Do not add user to the array if it was already added
                const exists = users.findIndex((user) => user.id === participant.id) !== -1
                if (!exists) users.push(participant)
              })
            })

            this.userRepository.setObjects(users)
            this.userRepository.sortObjects('name')
            this.$vs.loading.close()
          },
          (error) => {
            this.notifyError(this.$vs, this.$t('erro-ao-carregar-os-participantes-do-que'))
            this.$vs.loading.close()
          }
        )

        this.filtersChanged({questionnaires: questionnaires.map((questionnaireTagData) => {
          return questionnaireTagData.data.id
        })})
      } else {
        this.userRepository.clearObjects()
        this.filtersChanged({questionnaires: []})
      }
    },
    selectedUsers(users) {
      this.filtersChanged({users: users})
    }
  },

  computed: {
    validFilters() {
      return this._.get(this.questionnaires, 'length', 0) > 0
    },
    printMode() {
      return this.mode === 'print'
    },
    rootComponent() {
      switch (this.mode) {
      case 'print':
        return 'div'
      default:
        return 'vx-card'
      }
    },
    showBrowserWarning() {
      return !this.$utils.browser.isAtleastChrome(71)
    },
    currentAnswersPage() {
      if (this.perPage !== -1) {
        return this.answers.filter((value, index) => {
          return index < this.perPage
        })
      } else {
        return this.answers
      }
    },
    showLogo(){
      const hidden = this._.get(this.$route.query, 'hidden_logo')
      return !hidden
    },
    showScore(){
      const hidden = this._.get(this.$route.query, 'hidden_score')
      return !hidden
    },
    showQuestionScore(){
      const hidden = this._.get(this.$route.query, 'hidden_question_score')
      return !hidden
    },
    showResultScore(){
      const hidden = this._.get(this.$route.query, 'hidden_result_score')
      return !hidden
    }
  },

  methods: {
    loadAnswers(questionnaires, participants, questionsId) {
      this.$vs.loading()
      return this.service.getResultsReport(questionnaires, participants, questionsId, this.page, this.perPage).then(
        (response) => {
          this.$vs.loading.close()
          if (this._.isArray(response)) {
            if (response.length !== 0) {
              this.answers = this.handlingQuestionsOnPrint(response)
            } else {
              this.notifyWarning(this.$vs, this.$t('nenhuma-resposta-foi-encontrada'))
            }
          } else {
            this.notifyError(this.$t('erro-o-servidor-retornou-um-objeto-inval'))
          }
        },
        (error) => {
          this.$vs.loading.close()
        }
      )
    },
    print() {
      this.printReport()
    },
    getQuestionnaire(id) {
      return this.loadedQuestionnaires.find((questionnaire) => questionnaire.id === id)
    },
    getQuestionnaireLogo(id) {
      const questionnaire = this.getQuestionnaire(id)
      let logo = this._.get(questionnaire, 'organization_logo', null)
      return logo
    },
    clearFilters() {
      const questionnaireSelect = this.$refs['select_questionnaire']
      if (questionnaireSelect) {
        questionnaireSelect.reset()
      }
      this.selectedUsers = []
      this.questionnaires = []
      this.loadedQuestionnaires = []
      this.answers = []
    },
    printReport() {
      const routeData = this.$router.resolve({
        path: '/reports/questionnaire/results/print',
        query: {
          'u': this.selectedUsers,
          'q': this._.map(this.questionnaires, 'data')
        }
      })
      window.open(routeData.href, '_blank')
    },
    setupPrint() {
      let title = this.$t('relatorio-de-resultados')

      const loadedQuestionnaires = this.loadedQuestionnaires.length

      if (loadedQuestionnaires > 1) {
        const ids = this._.map(this.loadedQuestionnaires, 'id')
        title = `${title} - ${ids.join(' ')}`
      } else if (loadedQuestionnaires > 0) {
        const firstName = this._.get(this.loadedQuestionnaires, '0.name', '')
        title = `${title} - ${firstName}`
      }

      document.title = title

      this.$nextTick(() => {
        this.$vs.loading()
        setTimeout(() => {
          this.$vs.loading.close()
          window.print()
        }, 2000)
      })
    },
    filtersChanged(change) {
      if (this.hasData) {
        this.reset()
      }
    },
    handlingQuestionsOnPrint(response){
      let questionsId = this._.get(this.$route.query, 'qts', [])
      if (this._.isString(questionsId)) {
        questionsId = [questionsId]
      }
      if(!_.isEmpty(questionsId)){
        response.forEach((answer) => {
          answer.questions = answer.questions_by_filter
        })
      }

      return response
    }
  },

  beforeMount() {
    this.service = ReportService.build(this.$vs)
    this.questionnaireService = ContentQuestionnaireService.build(this.$vs)
    this.userRepository = new ObjectRepository()
    this.userRepository.suggestableProperties = ['name', 'email']
    this.organizationService = OrganizationService.build(this.$vs)
  },

  mounted() {
    if (this.printMode) {
      let questionnaires = this._.get(this.$route.query, 'q', [])
      let users = this._.get(this.$route.query, 'u', [])
      let questionsId = this._.get(this.$route.query, 'qts', [])

      const page = this._.get(this.$route.query, 'page', 1)
      const perPage = this._.get(this.$route.query, 'per_page', -1)
      this.perPage = parseInt(perPage)
      this.page = parseInt(page)

      if (this._.isString(questionnaires)) {
        questionnaires = [questionnaires]
      }

      if (this._.isString(users)) {
        users = [users]
      }

      if (this._.isString(questionsId)) {
        questionsId = [questionsId]
      }

      const dataRequests = []
      questionnaires.forEach((questionnaireId) => {
        dataRequests.push(this.questionnaireService.read(questionnaireId, {'res': ['logo']}))
      })

      Promise.all(dataRequests).then(
        (questionnaires) => {
          this.$vs.loading.close()
          this.loadedQuestionnaires = questionnaires
        },
        (error) => {
          this.notifyError(this.$vs, this.$t('nao-foi-possivel-carregar-os-dados-dos-q'))
        }
      ).finally(() => {
        this.loadAnswers(questionnaires, users, questionsId).finally(
          () => {
            this.setupPrint()
          },
        )
      })
    }
  }
}
</script>

<style scoped>
  @media print {
    .page {
      width: 100% !important;
    }
  }

  .page {
    width: 750px;
  }
</style>
