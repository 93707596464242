var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("vx-card", { staticClass: "border-grey border-solid border p-0" }, [
    _c("div", { staticClass: "grid grid-cols-12 gap-4" }, [
      _c(
        "div",
        { staticClass: "col-span-12" },
        [
          _c("vx-card", { staticClass: "mx-0 px-0 shadow-none" }, [
            _c("div", { staticClass: "grid gap-2" }, [
              _vm.questionnaireName
                ? _c(
                    "div",
                    { staticClass: "flex flex-col row-start-1 col-span-3" },
                    [
                      _c("label", { staticClass: "font-bold" }, [
                        _vm._v(_vm._s(_vm.$t("Questionnaire"))),
                      ]),
                      _c("span", [_vm._v(_vm._s(_vm.questionnaireName))]),
                    ]
                  )
                : _vm._e(),
              _vm.userName && _vm.userEmail
                ? _c("div", { staticClass: "flex flex-col row-start-2" }, [
                    _c("label", { staticClass: "font-bold" }, [
                      _vm._v(_vm._s(_vm.$t("usuario-a"))),
                    ]),
                    _c("span", [
                      _vm._v(
                        _vm._s(
                          _vm.$t("username-useremail", [
                            _vm.userName,
                            _vm.userEmail,
                          ])
                        )
                      ),
                    ]),
                  ])
                : _vm._e(),
              _vm.questionnairePublishedAt
                ? _c("div", { staticClass: "flex flex-col row-start-2" }, [
                    _c("label", { staticClass: "font-bold" }, [
                      _vm._v(_vm._s(_vm.$t("Release_Date"))),
                    ]),
                    _c("span", [_vm._v(_vm._s(_vm.questionnairePublishedAt))]),
                  ])
                : _vm._e(),
              _vm.answerFinishedAt
                ? _c("div", { staticClass: "flex flex-col row-start-2" }, [
                    _c("label", { staticClass: "font-bold" }, [
                      _vm._v(_vm._s(_vm.$t("data-finalizacao"))),
                    ]),
                    _c("span", [_vm._v(_vm._s(_vm.answerFinishedAt))]),
                  ])
                : _vm._e(),
              _vm.showResultScore &&
              _vm.answerScore &&
              _vm.questionnaireTotalScore
                ? _c("div", { staticClass: "flex flex-col row-start-4" }, [
                    _c("label", { staticClass: "font-bold" }, [
                      _vm._v(_vm._s(_vm.$t("points"))),
                    ]),
                    _c("span", [
                      _vm._v(
                        _vm._s(_vm.formatFloat(_vm.answerScore)) +
                          " / " +
                          _vm._s(_vm.formatFloat(_vm.questionnaireTotalScore)) +
                          " " +
                          _vm._s(_vm.scorePercentage())
                      ),
                    ]),
                  ])
                : _vm._e(),
            ]),
          ]),
        ],
        1
      ),
      _c("div", { staticClass: "col-span-12" }, [
        _c(
          "div",
          [
            _vm._l(_vm.answeredQuestionnaireFields, function (field, i) {
              return _c(
                "div",
                { key: i, staticClass: "px-4" },
                [
                  _c("vx-card", { class: _vm.getRowClass(field) }, [
                    _vm._.get(field, "type", null) !== "section"
                      ? _c("div", { staticClass: "grid grid-rows-1" }, [
                          _c("div", { staticClass: "grid grid-cols-2" }, [
                            _c("div", { staticClass: "float-left" }, [
                              _vm.showQuestionScore
                                ? _c(
                                    "span",
                                    { staticStyle: { "font-size": "12px" } },
                                    [_vm._v(_vm._s(_vm.scoreSet(field)))]
                                  )
                                : _vm._e(),
                            ]),
                            _c("div", { staticClass: "float-right" }, [
                              _vm.showScore
                                ? _c(
                                    "label",
                                    {
                                      class: field.correction
                                        ? "float-right text-right text-success"
                                        : "float-right text-right text-danger",
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(
                                          _vm.$t("points") +
                                            ": " +
                                            (field.score
                                              ? _vm.formmatedScore(field.score)
                                              : 0)
                                        ) + " "
                                      ),
                                    ]
                                  )
                                : _vm._e(),
                            ]),
                          ]),
                        ])
                      : _vm._e(),
                    _c(
                      "div",
                      [
                        field.type === "section"
                          ? _c("SectionAnswer", {
                              attrs: {
                                item: {
                                  data: {
                                    title: field.title,
                                    description: field.description,
                                  },
                                },
                              },
                            })
                          : _vm._e(),
                        field.type === "simple_text"
                          ? _c("SimpleTextShow", {
                              attrs: {
                                item: {
                                  data: Object.assign(
                                    {},
                                    _vm.getQuestionData(field),
                                    { title: field.title }
                                  ),
                                  answer: field.answer,
                                  correction: field.correction,
                                  feedback: field.feedback,
                                },
                              },
                            })
                          : _vm._e(),
                        field.type === "multiple_choice"
                          ? _c("MultipleChoiceShow", {
                              attrs: {
                                item: {
                                  data: Object.assign(
                                    {},
                                    _vm.getQuestionData(field),
                                    {
                                      title: field.title,
                                      options: field.options,
                                    }
                                  ),
                                  chosen_option_id: field.answer,
                                  correction: field.correction,
                                  feedback: field.feedback,
                                },
                                highlightCorrectBackground: false,
                              },
                            })
                          : _vm._e(),
                        field.type === "check_box"
                          ? _c("CheckBoxShow", {
                              attrs: {
                                item: {
                                  data: Object.assign(
                                    {},
                                    _vm.getQuestionData(field),
                                    {
                                      title: field.title,
                                      options: field.options,
                                    }
                                  ),
                                  chosen_option_id: field.answer,
                                  correction: field.correction,
                                  feedback: field.feedback,
                                },
                                highlightCorrectBackground: false,
                              },
                            })
                          : _vm._e(),
                        field.type === "gap"
                          ? _c("GapShow", {
                              attrs: {
                                item: {
                                  answer: field.answer,
                                  data: Object.assign(
                                    {},
                                    _vm.getQuestionData(field),
                                    {
                                      title: field.title,
                                      options: field.options,
                                    }
                                  ),
                                  chosen_option_id: field.answer,
                                  correction: field.correction,
                                  feedback: field.feedback,
                                },
                                highlightCorrectBackground: false,
                              },
                            })
                          : _vm._e(),
                        field.type === "essay"
                          ? _c("EssayShow", {
                              attrs: {
                                item: {
                                  data: Object.assign(
                                    {},
                                    _vm.getQuestionData(field),
                                    { title: field.title }
                                  ),
                                  answer: field.answer,
                                  correction: field.correction,
                                  feedback: field.feedback,
                                },
                              },
                            })
                          : _vm._e(),
                      ],
                      1
                    ),
                  ]),
                ],
                1
              )
            }),
            _vm.showLogo
              ? _c("div", { staticClass: "vx-col grid w-full mt-6" }, [
                  _vm.organizationLogo
                    ? _c(
                        "div",
                        {
                          staticClass:
                            "flex items-stretch mx-auto place-self-center",
                        },
                        [
                          _c("div", { staticClass: "flex items-center" }, [
                            _c(
                              "label",
                              {
                                staticClass:
                                  "text-black text-sm mr-2 text-center",
                              },
                              [_vm._v(_vm._s(_vm.$t("made_by")))]
                            ),
                          ]),
                          _c("img", {
                            staticStyle: { "max-width": "100px" },
                            attrs: { src: _vm.organizationLogo },
                          }),
                        ]
                      )
                    : _vm._e(),
                  _c(
                    "div",
                    {
                      staticClass:
                        "flex items-stretch mx-auto place-self-center",
                    },
                    [
                      _c(
                        "label",
                        { staticClass: "text-primary text-sm mr-2" },
                        [_vm._v(_vm._s(_vm.$t("Powered_by")))]
                      ),
                      _c(
                        "a",
                        {
                          attrs: {
                            href: "https://educatena.com.br",
                            target: "_blank",
                          },
                        },
                        [
                          _c("img", {
                            staticStyle: { "max-width": "100px" },
                            attrs: {
                              src: require("@/assets/images/logo/logo.png"),
                            },
                          }),
                        ]
                      ),
                    ]
                  ),
                ])
              : _vm._e(),
          ],
          2
        ),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }